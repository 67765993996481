<template>
  <v-container>
    <!-- Section Title -->
    <div class="headline text-center mb-4">
      <h3>Produk Affiliate</h3>
      <p>List Naskah yang bisa dicoba keuntungannya</p>
    </div>

    <!-- Card List -->
    <v-row>
      <v-col v-for="(naskah, index) in naskahList" :key="index" cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="350">
          <v-img :src="naskah.cover_url" height="200px" class="grey lighten-2"></v-img>

          <v-card-title>{{ formatTitle(naskah.title, 36) }}</v-card-title>

          <v-card-subtitle class="text--secondary">
            {{ formatAuthor(naskah.author, 99) }}
          </v-card-subtitle>

          <v-card-text>
            <p class="font-weight-bold">Harga: Rp {{ formatPrice(naskah.price) }}</p>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" text>Komisi: {{ formatPercentage(naskah.percentage) }}</v-btn>
            <v-btn color="success" @click="gabungReseller(naskah)">Tambah Produk</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- MODAL GABUNG -->
    <v-dialog v-model="marketDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Tambah Produk Affiliate</v-card-title>
        <v-card-text>
          <v-img :src="market.cover" height="200px" class="grey lighten-2 mt-3"></v-img>
          <p class="mt-4 mb-4">Apakah Anda ingin menjadi Affiliator untuk naskah <strong>{{ market.title }}</strong>?</p>

          <p class="mt-4 mb-4">Pendapatan Pertransaksi = Rp. {{ formatPrice(market.price * (market.percentage / 100) ) }}</p>
          <v-alert v-if="message" :type="messageType" dense>{{ message }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" @click="marketDialog = false">Batal</v-btn>
          <v-btn color="success" :loading="loading" @click="konfirmasiGabung">Tambahh</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      naskahList: [],
      marketDialog: false,
      loading: false,
      message: "",
      messageType: "success",
      market: {
        id: null,
        title: "",
        cover: "",
        affiliate_id: null,
      },
    };
  },
  methods: {
    async fetchAffiliateProducts() {
      try {
        const response = await axios.get("https://my.qrcbn.com/api/affiliate/produk");
        if (response.data.success) {
          this.naskahList = response.data.data;
        } else {
          console.error("Gagal mengambil data");
        }
      } catch (error) {
        console.error("Terjadi kesalahan", error);
      }
    },
    formatTitle(title, length) {
      if(title.length > length) {
        return title.substring(0, length) + '...'
      }
      return title.padEnd(length, ' ')
    },
    formatAuthor(author, length) {
      if(author.length > length) {
        return author.substring(0, length) + '...'
      }
      return author.padEnd(length, ' ')
    },
    formatPrice(price) {
      return price.toLocaleString("id-ID");
    },
    formatPercentage(value) {
      return `${parseInt(value)}%`;
    },
    gabungReseller(naskah) {
      console.log("Data naskah : ", naskah)
      this.market.id = naskah.id;
      this.market.title = naskah.title;
      this.market.cover = naskah.cover_url;
      this.market.affiliate_id = naskah.id;
      this.market.price = naskah.price;
      this.market.percentage = naskah.percentage;
      this.message = "";
      this.marketDialog = true;
    },
    async konfirmasiGabung() {
      this.loading = true;
      console.log("mengirim data : ", {affiliate_id: this.market.affiliate_id})
      try {
        const response = await axios.post("https://my.qrcbn.com/api/user/reseller", {
          affiliate_id: this.market.affiliate_id,
        });

        if (response.data.success) {
          this.message = "Berhasil bergabung sebagai reseller dari naskah ini!";
          this.messageType = "success";
        } else {
          this.message = response.data.message || "Gagal bergabung, coba lagi!";
          this.messageType = "error";
        }
      } catch (error) {
        this.message = "Terjadi kesalahan, coba lagi nanti!";
        this.messageType = "error";
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchAffiliateProducts();
  },
};
</script>

<style scoped>
.headline h3 {
  font-size: 24px;
  font-weight: bold;
}

@import "../../../public/oneui/assets/css/oneui.min.css";
</style>